.header{
    height: 65px;
    color:#fff;
    font-size: 16px;
    clear: both;
    width:100%;
    position: fixed;
    z-index:9999999;
    
}
.wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.fixed{
    background: #22a7ff;
}
.isPro{
    background:#22a7ff 
}
.header .container{
    width: 1200px;
    margin:0 auto;
}
.clearboth{
    clear: both;
}
@media screen and (max-width: 800px) {
    .header {
        display: block;
    }
}