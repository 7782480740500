#img-set{
    margin-top: 66px;
}
.rule{
    padding: 150px 0; 
    width:930px;
    margin:0 auto; 
    font-size: 16px;
    color: #666;
}
.rule .title{ 
    font-size: 18px; 
    color:#000; 
    font-weight: bold;
}
.container_mobile_about {
    
    margin: 0;
}
.container_mobile_about .rule {
    width: auto;
    padding: 20px 30px;
}
.container_mobile_handout img, .container_mobile_iospreview img, .container_mobile_lookcourse img, .container_mobile_preview img {
    width: 100%;
}
.container_mobile_about .spe_top {
    /* padding: 0;
    margin: 0;
    position: absolute; */
    width: 100%;
    height: 4.8em;
    background-color: #000;
    opacity: .8;
}

@media screen and (max-width: 1200px){
    .container {
    
        margin: 0;
    }
    .container .rule {
        width: auto;
        padding: 20px 30px;
    }
    .container img, .container img, .container img, .container img {
        width: 100%;
    }
    .container .spe_top {
        /* padding: 0;
        margin: 0;
        position: absolute; */
        width: 100%;
        height: 4.8em;
        background-color: #000;
        opacity: .8;
    }
}