@font-face {font-family: "iconfont";
  src: url('//at.alicdn.com/t/font_2504212_pes473r78fq.eot?t=1619173041615'); /* IE9 */
  src: url('//at.alicdn.com/t/font_2504212_pes473r78fq.eot?t=1619173041615#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAALsAAsAAAAABuQAAAKgAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHEIGVgCDBgqBTIFGATYCJAMMCwgABCAFhG0HNxsNBsgekiQlylugEARVAcA0xcPTGu39mdk9lXaXxBytJNFoWr2Kl0gphEKIZM/knZrTH7HKxb4sshqRKqlR4D/htt/PGIXaNKYDsgWhJmQr55YAneU+z+X0VkCBzA90jq09KDq6F2AcUKBjDYqsQBL0hrELXMJxAu1GebSdml7Mgl6FNS4Q9xSJA725uKKwUqtQ1ywt4pNKa3qdvgM+ht+Pf0LRS1Jl1tRzN6ZEMPrTXz6/ag5d9QkJAlxdIWMOUIjrtfmLOgQTdmjXd7bX1oqQ5ko1DaFc19b2Hx5JEDWruRNMoULiJ3DlqSEPlXfdlkHtNRr2AGdZ5sGxttwJr3hKn6/rR+6Nl5va4dJ65ahx47Tsw+r1zo3Pfu7vfvP16GUg9D0lfqrbYuvJvW1nkrfJaMXOJu+Sm42QjF6Rte2pZCUBwss/bN3RYjDTctRZiWk68QDM1p1NJsLWnOhkZ40FawCac3kUIN9sf/Vvf+PL2qdzdWTvf+tAAT+eG1843lz0L7RKMMWfVPTkFV1lOXJR5a7cDGZjd5VLaNdDGla4X2u8eehui6HVUIqkxQiyVmNkEcyh0mENtVaHaDdrZnWHAUSHKG2YcQYQen0g6fYNWa9fsgiaqAz7R603OtDufAxs2WEilP6TBHEUiZDfgopQMLHjov1RaxpJls6RrCoU5REpKykY9AWKpRlkIjLHjPK6FKIUQ0wKBpwGpyFdL8AiKahIoD6Z0mLK78d1b/IJBQP0O4lAOBQignhbIIWgwIT97lR/6fNpiMSi45AWTo2dhxBlSv9QkE+gBzmjN3tx7uWVsnWSEIrCIIwoMEDTwIvodr4AKtYPUiECykcekS1K8aNOuK/et77R+L490M7aM0eKHEV90+JMXum8j0wLAA==') format('woff2'),
  url('//at.alicdn.com/t/font_2504212_pes473r78fq.woff?t=1619173041615') format('woff'),
  url('//at.alicdn.com/t/font_2504212_pes473r78fq.ttf?t=1619173041615') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
  url('//at.alicdn.com/t/font_2504212_pes473r78fq.svg?t=1619173041615#iconfont') format('svg'); /* iOS 4.1- */
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.www-guanbi:before {
  content: "\e656";
}

.www-menu:before {
  content: "\e600";
}
