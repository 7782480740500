*{
  margin:0;
  padding:0;
  outline:none;
  font-family: "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei", "微软雅黑", "文泉驿微米黑", sans-serif;
}
html,body{
    font-family: "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei", "微软雅黑", "文泉驿微米黑", sans-serif;
    overflow-x: hidden;
}
.clearfix:after{
  content:".";
  display:block;
  height:0;
  clear:both;
  visibility:hidden
}
.clearfix{
  display:block
}
.pull-left{
  float:left;
  display: inline;
}
.pull-right{
  float:right;
  display: inline;
}
.container{
  width: 1200px;
  margin:0 auto;
  clear: both;
}
ul,li,ol{
    list-style:none;
}
img,a img{
    border:none;
    margin:0;
    padding:0;
}
a{
    text-decoration:none !important;
}
a:hover{
    text-decoration:none !important;
}
.bg_fff{
  padding:80px 0 60px;
  background: #fff;
}
.bg_fff_mobile{
  padding:30px 0 30px;
  background: #fff;
}
.bg_fff-mobile{
  padding-top: 0;
}
.bg_f4{
  padding:80px 0 60px;
  background: #f4f4f4;
}
.bg_f4_mobile {
  padding:30px 0 30px;
}
.bigTitle{
  font-size: 46px;
  color: #333;
  text-align: center;
  font-weight: bold;
}
.bigTitle_mobile {
  font-size: 28px;
  color: #333;
  text-align: center;
  font-weight: bold;
}
.bg_f4_mobile .container {
  /* padding: 0 30px; */
  padding: 0;
}
.smTitle{
  font-size:26px;
  color:#333;
  text-align: center!important;
}
.container_w750{
  width: 750px;
  margin:0 auto;
  clear: both;
}
.marBot80{
  margin-bottom: 55px!important;
}
.marBot30{
  margin-bottom: 30px!important;
}
@media screen and (max-width: 1200px){
    .container{
        width: 100%;
    }
    #footer{
       width:100%;
    }
}

