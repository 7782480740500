.course{
    width: 100%;
    padding-top: 65px;
}
.course p{
    margin-bottom: 0;
    text-align: justify;
    color: #333;
}
.course .colorOrange{
    color: #FF5F19;
}
.subNavWrap{
    display: flex;
    align-items: center;
    margin: 40px 0;
    position: relative;
}
.subNavWrap::after{
    content: '';
    position: absolute;
    left:1%;
    bottom: 0;
    background: #FF5F19;
    height: 2px;
    width: 98%;
}
.subNavWrap .subnormal{
    width:24%;
    height: 60px;
    line-height: 60px;
    background: #FFBD55;
    margin: 0 1%;
    color: #fff;
    font-size: 28px;
    text-align: center;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
}
.subNavWrap .active{
    background:#FF5F19 ;
}
.flex{
    display: flex;
    align-items: center;
}
.ban{
    width:500px;
    margin-right: 50px;
    float: left;
    margin-top:8px;
}
.content{
    float: left;
    width: 650px;
}
.content .courseName{
    font-size: 24px;
}
.moneyMain{
    background: #F4F4F4;
    font-size: 21px;
    padding: 0 30px;
    margin: 20px 0;
    height: 80px;
    line-height: 80px;
    overflow: hidden;
}
.moneyMain span{
    float: left;
}
.moneyMain .money{
    font-size: 36px;
    margin:0 20px 0 10px;
}
.moneyMain .linethrough{
    text-decoration: line-through;
    font-size: 16px;
    color: #999;
    text-align: center;
} 
.level{
    margin-bottom: 20px;
}
.level > span:first-child {
    height: 88px;
    float: left;
}
.level span{
    margin-right:10px
}
.level .normal{
    width: 90px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    border: 1px solid #BFBFBF;
    border-radius: 3px;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 10px;
}
.level .courseNormal{
    width: 150px;
    height: 65px;
    line-height: 65px;
    text-align: center;
    font-size: 16px;
    border: 1px solid #BFBFBF;
    border-radius: 3px;
    display: inline-block;
    cursor: pointer;
    background: url(../course/images/1v6.png) no-repeat 10px center;
    background-size: 50px;
    padding-left: 50px;
}
.level .active{
    border: 1px solid #FE7101;
}
.zixunbtn{
    width: 150px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    border: 1px solid #FE7101;
    border-radius: 3px;
    display: inline-block;
    cursor: pointer;
    color: #FE7101;
    margin-right: 30px;
}
.qrcodeMain{
    position: relative;
}
.qrcodeMain .qrcode{
    width: 150px;
    height: 150px;
    position: absolute;
    left:-170px;
    top: 0;
    background: url(../course/images/qrcodebg.png) no-repeat;
    background-size: cover;
    padding: 15px;
}

/* mobile */
@media screen and (max-width: 800px){
    .course {
        width: 100%;
        background-color: #22a7ff;
    }
    .course p{
        margin-bottom: 0;
        text-align: justify;
        color: #333;
    }
    .course .colorOrange{
        color: #FF5F19;
    }
    .course .bg_fff {
        padding: 0;
    }
    .course .bg_fff .container {
        padding: 0;
    }
    .course .bg_fff .container .ban {
        width: 100%;
        margin: 0;
    }
    .course .bg_fff .container .content {
        width: 100%;
        padding: 0 15px;
    }
    .course .bg_fff .container .content .moneyMain {
        height: 65px;
        line-height: 65px;
        font-size: 18px;
    }
    .course .bg_fff .container .content .level .normal {
        width: auto;
        height: auto;
        padding: .1em .73em;
        line-height: 1.7em;
        font-size: 14px;
    }
    .course .bg_fff .container .content .level .courseNormal {
        width: 6.9em;
        text-align: right;
        padding-right: 6px;
        background-size: 30px;
        height: 42px;
        line-height: 42px;
    }
    .course .bg_fff .container .content .qrcodeMain .zixunbtn {
        width: auto;
        height: auto;
        padding: 0em 1em;
    }
    .course .bg_f4 {
        background-color: #FFFFFF;
        padding: 30px 15px;
        text-indent: 2em;
    }
    /* .course .bg_f4_mobile {
        padding: 30px 0;
    } */
    .course .bg_f4 .container_w750 {
        width: 100%;
    }
    .course .bg_fff .container .bigTitle {
        font-size: 28px;
    }
    .course .bg_fff .container .smTitle {
        font-size: 18px;
        padding: 0 15px;
    }
    .course .bg_fff .container .subNavWrap {
        margin: 0;
    }
    .course .bg_fff .container .subNavWrap .subnormal {
        height: 35px;
        line-height: 35px;
        font-size: 18px;
    }
    .course .bg_f4 .container {
        padding: 0;
    }
    .course .bg_f4 .container .bigTitle {
        font-size: 28px;
    }
}

/* mobile由设备判断 */
.course_mobile {
    width: 100%;
}
.course_mobile p{
    margin-bottom: 0;
    text-align: justify;
    color: #333;
}
.course_mobile .colorOrange{
    color: #FF5F19;
}
.course_mobile .bg_fff {
    padding: 0 0 40px 0;
}
.course_mobile .bg_fff .container {
    padding: 0;
}
.course_mobile .bg_fff .container .ban {
    width: 100%;
    margin: 0;
}
.course_mobile .bg_fff .container .content {
    width: 100%;
    padding: 0 15px;
}
.course_mobile .bg_fff .container .content .moneyMain {
    height: 65px;
    line-height: 65px;
    font-size: 18px;
}
.course_mobile .bg_fff .container .content .level .normal {
    width: auto;
    height: auto;
    padding: .1em .73em;
    line-height: 1.7em;
    font-size: 14px;
    margin-bottom: 10px;
}
.course_mobile .bg_fff .container .content .level .courseNormal {
    width: 6.9em;
    text-align: right;
    padding-right: 6px;
    background-size: 30px;
    height: 42px;
    line-height: 42px;
}
.course_mobile .bg_fff .container .content .qrcodeMain .zixunbtn {
    width: auto;
    height: auto;
    padding: 0em 1em;
}
.course_mobile .bg_f4 {
    background-color: #FFFFFF;
    padding: 30px 15px;
    text-indent: 2em;
}
.course_mobile .bg_f4 .container_w750 {
    width: 100%;
}
.course_mobile .bg_fff .container .bigTitle {
    font-size: 28px;
}
.course_mobile .bg_fff .container .smTitle {
    font-size: 18px;
    padding: 0 15px;
}
.course_mobile .bg_fff .container .subNavWrap {
    margin: 0 7px 5px;
}
.course_mobile .bg_fff .container .subNavWrap_mobile {
    overflow-x: auto;
}
.course_mobile .bg_fff .container .subNavWrap_mobile .subnormal {
    flex-shrink: 0;
    width: 16%;
}
.course_mobile .bg_fff .container .subNavWrap .subnormal {
    height: 35px;
    line-height: 35px;
    font-size: 18px;
}
.course_mobile .bg_f4 .container {
    padding: 0;
}
.course_mobile .bg_f4 .container .bigTitle {
    font-size: 28px;
}